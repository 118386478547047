import * as React from "react";
import styled, { useTheme } from "styled-components";
import { motion } from "framer-motion";
import { FixedWidth, Header } from "../../layout/Semantic";
import { H1 } from "../../copy";
import Content from "../../../content/services.json";
import Circles from "../home/Circles";
import ScrollVisibilityObserver from "../../utils/ScrollVisibilityObserver";

const GearWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;

  & #lines {
    fill: ${({ theme, lineColor }) => (lineColor ? lineColor : "#828282")};
  }
`;

const GearCenter = (props, lineColor) => {
  return (
    <GearWrapper className="absolute" lineColor={lineColor} {...props}>
      <svg width="128px" height="128px" viewBox="0 0 128 128">
        <defs>
          <circle id="a" cx={64} cy={64} r={64} />
        </defs>
        <g
          transform="translate(-64 -64) translate(64 64)"
          stroke="none"
          strokeWidth={1}
          fill="none"
          fillRule="evenodd"
        >
          <mask id="b" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <g id="lines" mask="url(#b)" fill="#828282">
            <g transform="rotate(-45 -75.765 382.108)">
              <path d="M0.5 0.5H1.5V511.5H0.5z" />
              <path d="M9.5 0.5H10.5V511.5H9.5z" />
              <path d="M18.5 0.5H19.5V511.5H18.5z" />
              <path d="M27.5 0.5H28.5V511.5H27.5z" />
              <path d="M36.5 0.5H37.5V511.5H36.5z" />
              <path d="M45.5 0.5H46.5V511.5H45.5z" />
              <path d="M54.5 0.5H55.5V511.5H54.5z" />
              <path d="M63.5 0.5H64.5V511.5H63.5z" />
              <path d="M72.5 0.5H73.5V511.5H72.5z" />
              <path d="M81.5 0.5H82.5V511.5H81.5z" />
              <path d="M90.5 0.5H91.5V511.5H90.5z" />
              <path d="M99.5 0.5H100.5V511.5H99.5z" />
              <path d="M108.5 0.5H109.5V511.5H108.5z" />
              <path d="M117.5 0.5H118.5V511.5H117.5z" />
              <path d="M126.5 0.5H127.5V511.5H126.5z" />
              <path d="M135.5 0.5H136.5V511.5H135.5z" />
              <path d="M144.5 0.5H145.5V511.5H144.5z" />
              <path d="M153.5 0.5H154.5V511.5H153.5z" />
              <path d="M162.5 0.5H163.5V511.5H162.5z" />
              <path d="M171.5 0.5H172.5V511.5H171.5z" />
              <path d="M180.5 0.5H181.5V511.5H180.5z" />
              <path d="M189.5 0.5H190.5V511.5H189.5z" />
              <path d="M198.5 0.5H199.5V511.5H198.5z" />
              <path d="M207.5 0.5H208.5V511.5H207.5z" />
              <path d="M216.5 0.5H217.5V511.5H216.5z" />
              <path d="M225.5 0.5H226.5V511.5H225.5z" />
              <path d="M234.5 0.5H235.5V511.5H234.5z" />
              <path d="M243.5 0.5H244.5V511.5H243.5z" />
              <path d="M252.5 0.5H253.5V511.5H252.5z" />
              <path d="M261.5 0.5H262.5V511.5H261.5z" />
              <path d="M270.5 0.5H271.5V511.5H270.5z" />
              <path d="M279.5 0.5H280.5V511.5H279.5z" />
              <path d="M288.5 0.5H289.5V511.5H288.5z" />
              <path d="M297.5 0.5H298.5V511.5H297.5z" />
              <path d="M306.5 0.5H307.5V511.5H306.5z" />
              <path d="M315.5 0.5H316.5V511.5H315.5z" />
              <path d="M324.5 0.5H325.5V511.5H324.5z" />
              <path d="M333.5 0.5H334.5V511.5H333.5z" />
              <path d="M342.5 0.5H343.5V511.5H342.5z" />
              <path d="M351.5 0.5H352.5V511.5H351.5z" />
              <path d="M360.5 0.5H361.5V511.5H360.5z" />
              <path d="M369.5 0.5H370.5V511.5H369.5z" />
              <path d="M378.5 0.5H379.5V511.5H378.5z" />
              <path d="M387.5 0.5H388.5V511.5H387.5z" />
              <path d="M396.5 0.5H397.5V511.5H396.5z" />
              <path d="M405.5 0.5H406.5V511.5H405.5z" />
              <path d="M414.5 0.5H415.5V511.5H414.5z" />
              <path d="M423.5 0.5H424.5V511.5H423.5z" />
              <path d="M432.5 0.5H433.5V511.5H432.5z" />
              <path d="M441.5 0.5H442.5V511.5H441.5z" />
              <path d="M450.5 0.5H451.5V511.5H450.5z" />
              <path d="M459.5 0.5H460.5V511.5H459.5z" />
              <path d="M468.5 0.5H469.5V511.5H468.5z" />
              <path d="M477.5 0.5H478.5V511.5H477.5z" />
              <path d="M486.5 0.5H487.5V511.5H486.5z" />
              <path d="M495.5 0.5H496.5V511.5H495.5z" />
            </g>
          </g>
        </g>
      </svg>
    </GearWrapper>
  );
};

const GearOuter = (props, lineColor) => {
  return (
    <GearWrapper className="absolute" lineColor={lineColor} {...props}>
      <svg width="256px" height="256px" viewBox="0 0 256 256">
        <defs>
          <path
            d="M87.173 198.126c-38.299-23.19-50.623-73.16-27.528-111.612C82.75 48.06 132.52 35.688 170.827 58.878c38.299 23.19 50.623 73.161 27.528 111.613-23.105 38.453-72.875 50.825-111.182 27.635zm144.378 5.405c2.91-4.056 5.651-8.289 8.122-12.729 2.42-4.3 4.526-8.723 6.37-13.186l-19.525-16.742 5.025-25.054L256 127.937a129.227 129.227 0 00-3.41-29.507l-25.632-1.995-10.643-23.226 15.186-20.838a126.907 126.907 0 00-19.416-21.261L190.09 44.517l-22.122-12.69.245-25.832C158.671 2.837 148.942.84 139.196 0l-9.931 23.813-25.337 2.817-14.94-20.98a127.068 127.068 0 00-26.953 12.27l5.897 25.078L49.134 60.23l-24.39-8.142c-2.994 4.107-5.828 8.445-8.4 13.036a139.788 139.788 0 00-6.21 12.778l19.5 16.808-5.1 25.038L0 127.533c0 9.99 1.1 19.948 3.342 29.6l25.633 2.08 10.574 23.257-15.278 20.788c5.753 7.951 12.41 15.346 20.007 21.922l21.962-13.49 22.181 12.597-.194 25.828a128.39 128.39 0 0029.05 5.885l9.856-23.838 25.328-2.928 14.99 20.936c9.408-3.057 18.452-7.169 26.937-12.343l-6.024-25.072 18.764-17.339 24.423 8.115z"
            id="c"
          />
        </defs>
        <g
          transform="translate(0 1)"
          stroke="none"
          strokeWidth={1}
          fill="none"
          fillRule="evenodd"
        >
          <mask id="d" fill="#fff">
            <use xlinkHref="#c" />
          </mask>
          <g mask="url(#d)" id="lines" fill="#828282">
            <g transform="rotate(45 344.327 47.398)">
              <path d="M0.5 0.5H1.5V513.507843H0.5z" />
              <path d="M9.5 0.5H10.5V513.507843H9.5z" />
              <path d="M18.5 0.5H19.5V513.507843H18.5z" />
              <path d="M27.5 0.5H28.5V513.507843H27.5z" />
              <path d="M36.5 0.5H37.5V513.507843H36.5z" />
              <path d="M45.5 0.5H46.5V513.507843H45.5z" />
              <path d="M54.5 0.5H55.5V513.507843H54.5z" />
              <path d="M63.5 0.5H64.5V513.507843H63.5z" />
              <path d="M72.5 0.5H73.5V513.507843H72.5z" />
              <path d="M81.5 0.5H82.5V513.507843H81.5z" />
              <path d="M90.5 0.5H91.5V513.507843H90.5z" />
              <path d="M99.5 0.5H100.5V513.507843H99.5z" />
              <path d="M108.5 0.5H109.5V513.507843H108.5z" />
              <path d="M117.5 0.5H118.5V513.507843H117.5z" />
              <path d="M126.5 0.5H127.5V513.507843H126.5z" />
              <path d="M135.5 0.5H136.5V513.507843H135.5z" />
              <path d="M144.5 0.5H145.5V513.507843H144.5z" />
              <path d="M153.5 0.5H154.5V513.507843H153.5z" />
              <path d="M162.5 0.5H163.5V513.507843H162.5z" />
              <path d="M171.5 0.5H172.5V513.507843H171.5z" />
              <path d="M180.5 0.5H181.5V513.507843H180.5z" />
              <path d="M189.5 0.5H190.5V513.507843H189.5z" />
              <path d="M198.5 0.5H199.5V513.507843H198.5z" />
              <path d="M207.5 0.5H208.5V513.507843H207.5z" />
              <path d="M216.5 0.5H217.5V513.507843H216.5z" />
              <path d="M225.5 0.5H226.5V513.507843H225.5z" />
              <path d="M234.5 0.5H235.5V513.507843H234.5z" />
              <path d="M243.5 0.5H244.5V513.507843H243.5z" />
              <path d="M252.5 0.5H253.5V513.507843H252.5z" />
              <path d="M261.5 0.5H262.5V513.507843H261.5z" />
              <path d="M270.5 0.5H271.5V513.507843H270.5z" />
              <path d="M279.5 0.5H280.5V513.507843H279.5z" />
              <path d="M288.5 0.5H289.5V513.507843H288.5z" />
              <path d="M297.5 0.5H298.5V513.507843H297.5z" />
              <path d="M306.5 0.5H307.5V513.507843H306.5z" />
              <path d="M315.5 0.5H316.5V513.507843H315.5z" />
              <path d="M324.5 0.5H325.5V513.507843H324.5z" />
              <path d="M333.5 0.5H334.5V513.507843H333.5z" />
              <path d="M342.5 0.5H343.5V513.507843H342.5z" />
              <path d="M351.5 0.5H352.5V513.507843H351.5z" />
              <path d="M360.5 0.5H361.5V513.507843H360.5z" />
              <path d="M369.5 0.5H370.5V513.507843H369.5z" />
              <path d="M378.5 0.5H379.5V513.507843H378.5z" />
              <path d="M387.5 0.5H388.5V513.507843H387.5z" />
              <path d="M396.5 0.5H397.5V513.507843H396.5z" />
              <path d="M405.5 0.5H406.5V513.507843H405.5z" />
              <path d="M414.5 0.5H415.5V513.507843H414.5z" />
              <path d="M423.5 0.5H424.5V513.507843H423.5z" />
              <path d="M432.5 0.5H433.5V513.507843H432.5z" />
              <path d="M441.5 0.5H442.5V513.507843H441.5z" />
              <path d="M450.5 0.5H451.5V513.507843H450.5z" />
              <path d="M459.5 0.5H460.5V513.507843H459.5z" />
              <path d="M468.5 0.5H469.5V513.507843H468.5z" />
              <path d="M477.5 0.5H478.5V513.507843H477.5z" />
              <path d="M486.5 0.5H487.5V513.507843H486.5z" />
              <path d="M495.5 0.5H496.5V513.507843H495.5z" />
            </g>
          </g>
        </g>
      </svg>
    </GearWrapper>
  );
};

const Gear = ({
  scale = 1,
  props,
  style,
  lineColor = "#828282",
  direction = "clockwise",
  isInView = false,
}) => {
  return (
    <GearWrapper
      className="absolute"
      style={{ scale, ...style }}
      {...props}
      variants={{
        show: {
          opacity: 1,
        },
        hide: {
          opacity: 0,
        },
      }}
      animate={isInView ? "show" : "hide"}
    >
      <GearOuter
        lineColor={lineColor}
        variants={{
          rotator: {
            rotate: direction === "clockwise" ? 360 : -360,
            transition: {
              repeat: Infinity,
              duration: 5,
              ease: "linear",
            },
          },
        }}
        animate="rotator"
      />
      <GearCenter lineColor={lineColor} />
    </GearWrapper>
  );
};

const Hero = () => {
  const theme = useTheme();
  return (
    <Header
      style={{
        backgroundColor: theme.COLORS.abyss,
        alignItems: "center",
      }}
    >
      <H1 style={{ color: theme.COLORS.snow }}>{Content.title}</H1>

      <ScrollVisibilityObserver>
        {({ isInView }) => {
          return (
            <FixedWidth
              className="relative"
              style={{ alignItems: "center", padding: "192px 0 96px" }}
              initial={{ opacity: 0 }}
              variants={{
                hidden: {
                  opacity: 0,
                },
                show: {
                  opacity: 1,
                  transition: {
                    when: "beforeChildren",
                    staggerChildren: 0.15,
                  },
                },
              }}
              animate={isInView ? "show" : "hidden"}
            >
              <Circles
                className="absolute"
                style={{ x: 128, y: 96 }}
                isInView={isInView}
                size={112}
                bgColor="vermilion"
                label={Content.hero.service_2}
                thinner
              />
              <Circles
                className="absolute"
                style={{ x: -128, y: -172 }}
                isInView={isInView}
                size={156}
                label={Content.hero.service_1}
                thinner
                extraPattern
              />

              <Circles
                className="absolute"
                style={{ x: 160, y: -384 }}
                isInView={isInView}
                size={64}
                label={null}
                thinner
              />
              <Gear style={{ x: 384, y: -96 }} isInView />
              <Gear
                isInView
                style={{ x: -384, y: 256 }}
                scale={0.8}
                lineColor="#BDBDBD"
                direction="counter-clockwise"
              />
            </FixedWidth>
          );
        }}
      </ScrollVisibilityObserver>
    </Header>
  );
};

export default Hero;
