import * as React from "react";
import styled, { useTheme } from "styled-components";
import { motion } from "framer-motion";
import usePageWidth from "../hooks/usePageWidth";
import PageWrapper from "../components/layouts/Page";
import Hero from "../components/pages/services/Hero";
import {
  ArticleColumn,
  FixedWidth,
  Main,
  Section,
  Grid,
} from "../components/layout/Semantic";
import Content from "../content/services.json";
import { H2, H3, H4, List, P } from "../components/copy";
import ServicesHeader1 from "../assets/images/svg/services_header_1.svg";
import ServicesHeader2 from "../assets/images/svg/services_header_2.svg";

const ArticleColumnMod = styled(ArticleColumn)`
  flex-shrink: 0;
`;

const CalloutContainer = styled(motion.div)`
  margin: ${({ isMobile, noShadow, direction }) =>
    isMobile
      ? "24px 0"
      : noShadow
      ? "24px"
      : direction === "right"
      ? "24px 96px 24px 32px"
      : "24px 32px 24px 96px"};
  padding: 30px;
  position: relative;
`;
const CalloutWrapper = styled(motion.div)`
  background-color: ${({ theme, bgColor }) =>
    bgColor ? theme.COLORS[bgColor] : "#fff"};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
`;

const CalloutShadow = styled.div`
  position: absolute;
  width: 120%;
  height: 110%;
  top: 20px;
  left: ${({ direction }) => (direction === "right" ? "25px" : "-115px")};
  background: linear-gradient(
    45deg,
    ${({ theme }) => theme.COLORS.vermilion},
    ${({ theme }) => theme.COLORS.marine},
    ${({ theme }) => theme.COLORS.abyss}
  );
  clip-path: ${({ direction = "right" }) => {
    if (direction === "right") {
      return "polygon(0 0,82% 0,93% 100%,0 90%)";
    }
    return "polygon(15% 0, 100% 0, 100% 85%, 0 100%);";
  }};
  z-index: 0;
`;

const Callout = ({
  children,
  bgColor = null,
  noShadow = false,
  direction = "right",
}) => {
  const { isMobile } = usePageWidth();
  return (
    <CalloutContainer
      isMobile={isMobile}
      direction={direction}
      noShadow={noShadow}
    >
      {!noShadow && !isMobile && <CalloutShadow direction={direction} />}
      <CalloutWrapper bgColor={bgColor} />
      {children}
    </CalloutContainer>
  );
};

// markup
const OurServicesPage = (props) => {
  const theme = useTheme();
  const { isMobile } = usePageWidth();

  return (
    <PageWrapper {...props} name="services">
      <Hero />
      <Main>
        <Section bgColor="snow" style={{ padding: "20px 20px 128px" }}>
          <FixedWidth>
            <Grid>
              <ArticleColumnMod>
                <H2 style={{ margin: "56px 0" }}>
                  {Content.sections.industry_1.title}
                </H2>
              </ArticleColumnMod>
              <ArticleColumnMod></ArticleColumnMod>
            </Grid>
          </FixedWidth>
          <FixedWidth>
            <Grid>
              <ArticleColumnMod
                isMobile={isMobile}
                style={{
                  flexBasis: isMobile ? "100%" : "50%",
                  order: isMobile ? 2 : 1,
                }}
              >
                <H3 color="vermilion">
                  {Content.sections.industry_1.sub_section_1.title}
                </H3>
                <H4>
                  {Content.sections.industry_1.sub_section_1.article_1.subtitle}
                </H4>
                <P>
                  {Content.sections.industry_1.sub_section_1.article_1.blurb}
                </P>
              </ArticleColumnMod>
              <ArticleColumnMod
                isMobile={isMobile}
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  flexBasis: isMobile ? "100%" : "50%",
                  order: isMobile ? 1 : 2,
                }}
              >
                <ServicesHeader1 width="100%" />
              </ArticleColumnMod>
            </Grid>
            <Grid>
              <ArticleColumnMod
                isMobile={isMobile}
                style={{ flexBasis: isMobile ? "100%" : "50%" }}
              >
                <Callout>
                  <List
                    items={
                      Content.sections.industry_1.sub_section_1.credentials
                    }
                  />
                </Callout>
              </ArticleColumnMod>
              <ArticleColumnMod
                isMobile={isMobile}
                style={{ flexBasis: isMobile ? "100%" : "50%" }}
              >
                <H4>
                  {Content.sections.industry_1.sub_section_1.article_2.subtitle}
                </H4>
                <P>
                  {Content.sections.industry_1.sub_section_1.article_2.blurb}
                </P>
                <H4>
                  {Content.sections.industry_1.sub_section_1.article_3.subtitle}
                </H4>
                <P>
                  {Content.sections.industry_1.sub_section_1.article_3.blurb}
                </P>
              </ArticleColumnMod>
            </Grid>
          </FixedWidth>
        </Section>
        <Section style={{ padding: "56px 20px 128px" }}>
          <FixedWidth>
            <H3 color="vermilion" style={{ width: isMobile ? "100%" : "50%" }}>
              {Content.sections.industry_1.sub_section_2.title}
            </H3>
          </FixedWidth>
          <FixedWidth>
            <Grid>
              <ArticleColumnMod
                style={{ flexBasis: isMobile ? "100%" : "50%" }}
              >
                <H4>
                  {Content.sections.industry_1.sub_section_2.article_1.subtitle}
                </H4>
                <P>
                  {Content.sections.industry_1.sub_section_2.article_1.blurb}
                </P>
              </ArticleColumnMod>
              <ArticleColumnMod
                style={{ flexBasis: isMobile ? "100%" : "50%" }}
              >
                <Callout bgColor="snow">
                  <List
                    items={
                      Content.sections.industry_1.sub_section_2.article_1
                        .credentials
                    }
                  />
                </Callout>
              </ArticleColumnMod>
            </Grid>
            <Grid>
              <ArticleColumnMod
                style={{
                  flexBasis: isMobile ? "100%" : "50%",
                  order: isMobile ? 2 : 1,
                }}
              >
                <Callout bgColor="snow" direction="left">
                  <List
                    items={
                      Content.sections.industry_1.sub_section_2.article_2
                        .credentials
                    }
                  />
                </Callout>
              </ArticleColumnMod>
              <ArticleColumnMod
                style={{
                  flexBasis: isMobile ? "100%" : "50%",
                  order: isMobile ? 1 : 2,
                }}
              >
                <H4>
                  {Content.sections.industry_1.sub_section_2.article_2.subtitle}
                </H4>
                <P>
                  {Content.sections.industry_1.sub_section_2.article_2.blurb}
                </P>
              </ArticleColumnMod>
            </Grid>
            <Grid style={{ margin: "96px 0 0" }}>
              <ArticleColumnMod
                style={{ flexBasis: isMobile ? "100%" : "50%" }}
              >
                <H4>
                  {Content.sections.industry_1.sub_section_2.article_3.subtitle}
                </H4>
                <P>
                  {Content.sections.industry_1.sub_section_2.article_3.blurb}
                </P>
              </ArticleColumnMod>
              <ArticleColumnMod
                style={{ flexBasis: isMobile ? "100%" : "50%" }}
              >
                <Callout bgColor="snow">
                  <List
                    items={
                      Content.sections.industry_1.sub_section_2.article_3
                        .credentials
                    }
                  />
                </Callout>
              </ArticleColumnMod>
            </Grid>
          </FixedWidth>
        </Section>
        <Section bgColor="abyss" style={{ padding: "56px 20px" }}>
          <FixedWidth>
            <H2 darkMode>{Content.sections.industry_2.title}</H2>
          </FixedWidth>
          <FixedWidth>
            <Grid>
              <ArticleColumnMod
                style={{
                  flexBasis: isMobile ? "100%" : "50%",
                  order: isMobile ? 2 : 1,
                }}
              >
                <H3 patternColor="darker" color="marine">
                  {Content.sections.industry_2.sub_section_1.title}
                </H3>
                <P style={{ color: theme.COLORS.snow }}>
                  {Content.sections.industry_2.sub_section_1.blurb}
                </P>
              </ArticleColumnMod>
              <ArticleColumnMod
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  flexBasis: isMobile ? "100%" : "50%",
                  order: isMobile ? 1 : 2,
                }}
              >
                <ServicesHeader2 width="100%" />
              </ArticleColumnMod>
            </Grid>
            <Grid>
              <ArticleColumnMod>
                <Callout bgColor="marine" noShadow>
                  <List
                    horizontal
                    textColor="snow"
                    listColor="snow"
                    items={
                      Content.sections.industry_2.sub_section_1.credentials
                    }
                  />
                </Callout>
              </ArticleColumnMod>
            </Grid>
          </FixedWidth>
        </Section>
        <Section bgColor="rainyday" style={{ padding: "56px 20px" }}>
          <FixedWidth>
            <H3
              patternColor="silverfox"
              color="vermilion"
              style={{ width: isMobile ? "100%" : "50%" }}
            >
              {Content.sections.industry_2.sub_section_2.title}
            </H3>
            <Grid>
              <ArticleColumnMod
                style={{ flexBasis: isMobile ? "100%" : "50%" }}
              >
                <P>{Content.sections.industry_2.sub_section_2.blurb}</P>
              </ArticleColumnMod>
              <ArticleColumnMod
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  flexBasis: isMobile ? "100%" : "50%",
                }}
              >
                <Callout bgColor="snow">
                  <List
                    listColor="vermilion"
                    items={
                      Content.sections.industry_2.sub_section_2.credentials
                    }
                  />
                </Callout>
              </ArticleColumnMod>
            </Grid>
          </FixedWidth>
        </Section>
      </Main>
    </PageWrapper>
  );
};

export default OurServicesPage;
